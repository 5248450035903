import { defineStore } from "pinia";

const useContractStore = defineStore("contract", {
  state: () => ({
    contractFileList: [],
    conversationId: "",
  }),
  actions: {
    setContractFileList(data: any) {
      this.contractFileList = data;
    },
    setConversationId(id: string) {
      this.conversationId = id;
    },
    resetContract() {
      this.$reset();
    },
  },
});

export default useContractStore;
