<!-- CustomerServiceModal.vue -->
<template>
  <n-modal
    :show="show"
    class="custom-card"
    preset="card"
    :style="{ width: '700px' }"
    size="huge"
    :bordered="false"
    header-style="padding:15px"
    :closable="false"
  >
    <!-- <h2 style="text-align: center; color: #0055ff">木瓜法宝</h2>
    <n-space>
      <n-image width="200" src="/serverImage.png"></n-image>
      <div style="padding-top: 20px; margin-left: 20px">
        <div>扫一扫</div>
        <div>添加木瓜法宝客服</div>
        <div style="padding-top: 50px">更多优惠详情请咨询客服</div>
      </div>
    </n-space> -->
    <div
      style="margin-top: 20px; display: flex; justify-content: space-between"
    >
      <n-card content-style="padding:0" bordered class="card">
        <n-tag type="warning" round class="tag">限前20位</n-tag>
        <n-space align="center" justify="space-between">
          <h3 class="title">合同审查年卡</h3>
          <div class="price">
            <span class="current-price">365</span>
            <span class="unit">/年</span>
          </div>
        </n-space>
        <ul class="features">
          <li>合同审查功能</li>
          <li>自动分析</li>
          <li>价格优惠</li>
          <li>专属客服</li>
        </ul>
      </n-card>
      <n-card content-style="padding:0" bordered class="card">
        <div class="combo-card">
          <div>
            <h3 class="title">套餐年卡</h3>
            <ul class="features">
              <li>所有功能</li>
              <li>价格优惠</li>
              <li>专属客服</li>
            </ul>
          </div>
          <div style="margin-top: 2vh">
            <n-image width="100" src="/serverImage.png"></n-image>
            <p style="text-align: center; margin-top: 3px; color: #ccc">
              请咨询客服
            </p>
          </div>
        </div>
      </n-card>
    </div>
    <n-card
      style="margin-top: 20px; width: 100%"
      content-style="padding:0"
      bordered
      class="card"
    >
      <n-tabs type="bar">
        <n-tab-pane name="zfb" tab="支付宝">
          <n-button type="info" @click="handleGoBuy">点击购买年卡</n-button>
        </n-tab-pane>
        <n-tab-pane name="ask" tab="咨询购买">
          <div style="width: 100%; text-align: center">
            <n-image width="150" src="/serverImage.png"></n-image>
            <p>请扫描二维码, 联系工作人员</p>
          </div>
        </n-tab-pane>
      </n-tabs>
    </n-card>
    <template #footer>
      <div style="width: 100%; text-align: center">
        <n-button type="info" @click="closeModal">关闭</n-button>
      </div>
    </template>
  </n-modal>
  <n-modal
    :show="paymentConfirmModalVisible"
    class="custom-card"
    preset="card"
    size="huge"
    :bordered="false"
    header-style="padding:15px"
    :closable="false"
    :style="{ width: '700px' }"
  >
    <template v-if="!paymentResult">
      <div
        style="
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <p>支付是否已完成？</p>
        <div class="customBtn">
          <n-button type="info" @click="handlePaymentCompleted"
            >已完成</n-button
          >
          <n-button type="default" @click="closePaymentConfirmModal"
            >未完成</n-button
          >
        </div>
      </div>
    </template>

    <template v-else-if="paymentResult === 'success'">
      <div style="text-align: center">
        <n-icon size="60" color="#0e7a0d">
          <CheckmarkCircle />
        </n-icon>
        <h3 style="margin-bottom: 50px">下单成功</h3>
        <div style="text-align: left; padding-left: 80px">
          <p>购买商品: {{ orderInfo.rightsName }}</p>
          <p>持续时间: {{ orderInfo.validDate }}天</p>
          <p>支付方式: {{ orderInfo.paymentMethod }}</p>
          <p>实付金额: {{ orderInfo.feFinalAmount }}</p>
          <p>订单编号: {{ orderInfo.orderId }}</p>
          <p>购买时间: {{ new Date(orderInfo.createTime).toLocaleString() }}</p>
        </div>
        <n-button
          style="margin-top: 50px"
          type="info"
          @click="closePaymentConfirmModal"
          >关闭</n-button
        >
      </div>
    </template>

    <template v-else>
      <div style="text-align: center">
        <n-icon size="60" color="#ed373c">
          <CloseCircleSharp />
        </n-icon>
        <h3 style="margin-bottom: 50px">订单查询失败</h3>
        <p style="color: #ccc">
          温馨提示: 如您已支付成功, 请您耐心等待几秒钟,
          点击重试以查看最新的订单信息
        </p>
        <p style="color: #ccc">
          如果刷新后订单状态仍未更新, 请及时联系我们的客服团队
        </p>
        <div style="margin-top: 50px">
          <n-button
            style="margin-right: 50px"
            type="warning"
            @click="retryCheckPaymentStatus"
            >重试</n-button
          >
          <n-button type="info" @click="closePaymentConfirmModal"
            >关闭</n-button
          >
        </div>
      </div>
    </template>

    <template v-if="paymentResult" #footer>
      <div style="width: 100%; text-align: center"></div>
    </template>
  </n-modal>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, onMounted } from "vue";
import {
  getRightsListApi,
  checkPaymentStatusApi,
  getNewOrderApi,
} from "@/api/pay";
import { useUserStore } from "@/store";
import { CheckmarkCircle, CloseCircleSharp } from "@vicons/ionicons5";
import { Message } from "@/utils/tips";

const props = defineProps({
  show: Boolean,
});
const emit = defineEmits(["update:show"]);

const yearRightsInfo = ref();
const userStore = useUserStore();
const paymentConfirmModalVisible = ref(false);
const paymentResult = ref<any>(null);
const orderId = ref("");
const orderInfo = ref<any>();

const checkPaymentStatus = () => {
  if (orderId.value) {
    checkPaymentStatusApi(orderId.value)
      .then((res: any) => {
        if (res.data.code === 200 && res.data.data) {
          paymentResult.value = "success";
        } else {
          paymentResult.value = "failed";
        }
      })
      .catch((err: any) => {
        paymentResult.value = "failed";
        console.error(err);
      });
  } else {
    Message.error("获取订单信息失败");
  }
};

const retryCheckPaymentStatus = () => {
  paymentResult.value = null;
  checkPaymentStatus();
};

const closePaymentConfirmModal = () => {
  paymentConfirmModalVisible.value = false;
  paymentResult.value = null;
};

const closeModal = () => {
  emit("update:show", false);
};

const openPaymentConfirmModal = () => {
  paymentConfirmModalVisible.value = true;
};

const handleGoBuy = () => {
  const userId = userStore.javaUser?.userId;
  if (yearRightsInfo.value) {
    // const viewerUrl = `http://192.168.2.245:8084/api2/alipay/alipay?userId=${userId}&rightsId=${yearRightsInfo.value.rightsId}&interfaceInfoId=5&feTotalAmount=365&feDiscountAmount=5.0&feFinalAmount=0.01&paymentMethod=支付宝`;
    const viewerUrl = `${window.location.origin}/api2/alipay/alipay?userId=${userId}&rightsId=${yearRightsInfo.value.rightsId}&interfaceInfoId=5&feTotalAmount=365&feDiscountAmount=5.0&feFinalAmount=365&paymentMethod=支付宝`;
    const link = document.createElement("a");
    link.href = viewerUrl;
    link.target = "_blank"; // Ensure it opens in a new tab
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // 关闭当前购买弹窗
    closeModal();

    // 打开支付确认弹窗
    openPaymentConfirmModal();
  }
};

const handlePaymentCompleted = async () => {
  const userId = userStore.javaUser?.userId;
  await getNewOrderApi(userId)
    .then((res) => {
      console.log(res);
      orderId.value = res.data.data.orderId;
      orderInfo.value = res.data.data;
    })
    .catch((err) => {
      console.log(err);
    });
  checkPaymentStatus();
};

onMounted(() => {
  getRightsListApi(false)
    .then((res) => {
      console.log(res);
      if (res.data && res.data.data) {
        const yearCardInfo = res.data.data.filter(
          (item: any) => item.rightsTypeId === 4
        );
        yearRightsInfo.value = yearCardInfo[0];
      }
    })
    .catch((err) => {
      console.log(err);
    });
});
</script>

<style scoped>
.card {
  width: 300px;
  padding: 20px;
  border: 1px solid #eee;
}

.tag {
  position: absolute;
  top: -12px;
  left: -12px;
  padding: 0 8px;
}

.title {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.price {
  font-size: 24px;
  color: #1d75ee;
}

.current-price {
  font-weight: bold;
}

.unit {
  font-size: 14px;
  margin-right: 5px;
  color: black;
}

.original-price {
  font-size: 14px;
  color: #999;
  text-decoration: line-through;
}

.features {
  margin-top: 10px;
  padding-left: 20px;
  list-style-type: none;
}

.features li {
  margin-bottom: 5px;
}

.combo-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customBtn {
  display: flex;
  justify-content: space-between;
  width: 200px;
}
</style>
