<script setup lang="ts">
import { ref, defineProps, defineEmits, onMounted, watch } from "vue";
import {
  getUserModalInfo,
  getVerificationCode,
  bindUserPhone,
  updateUserPassword,
} from "@/api/user";
import { useUserStore } from "@/store";
import { Message } from "@/utils/tips"; // 假设你有一个消息提示工具

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const userInfo = ref<any>({
  nickName: "",
  userName: "",
  userPhoneNumber: "",
  gender: "",
  userPassword: "111111111",
  company: "",
  position: "",
  industry: "",
  location: "",
});

const emit = defineEmits(["update:show", "save"]);
const userStore = useUserStore();
const userId = ref(userStore.javaUser.userId);
const changePasswordBtnLoading = ref(false);
const changeBindPhoneBtnLoading = ref(false);

const genderOptions = [
  { label: "男", value: "男" },
  { label: "女", value: "女" },
];

function handleSave() {
  console.log("保存用户信息:", userInfo.value);
  emit("save", userInfo.value);
}

const afterClose = () => {
  userInfo.value = {
    nickName: "",
    userName: "",
    userPhoneNumber: "",
    gender: "",
    company: "",
    position: "",
    industry: "",
    location: "",
    userPassword: "111111111",
  };
  getUserInfo();
};

const getUserInfo = async () => {
  await getUserModalInfo(userId.value)
    .then((res) => {
      console.log(res);
      const { data } = res.data;
      userInfo.value = {
        nickName: data.nickName,
        userName: data.userName,
        userPhoneNumber: data.userPhoneNumber,
        gender: data.gender,
        company: data.company,
        position: data.position,
        industry: data.industry,
        location: data.location,
        userPassword: "111111111",
      };
    })
    .catch((err) => {
      console.log(err);
    });
};

onMounted(() => {
  getUserInfo();
});

// 绑定手机号弹窗相关逻辑
const showBindPhoneModal = ref(false);
const bindPhoneForm = ref({
  phone: userInfo.value.userPhoneNumber, // 初始值为一级弹窗的手机号
  code: "",
});
const sendCodeDisabled = ref(false);
const sendCodeText = ref("发送验证码");

const sendCode = () => {
  if (!bindPhoneForm.value.phone) {
    Message.warning("请输入手机号");
    return;
  }
  if (sendCodeDisabled.value) return;
  sendCodeDisabled.value = true;
  getVerificationCode(bindPhoneForm.value.phone)
    .then((res) => {
      Message.success("发送验证码成功");
    })
    .catch((err) => {
      Message.error("验证码发送失败, 请检查网络连接");
    });
  let count = 60;
  sendCodeText.value = `${count}秒后重试`;
  const timer = setInterval(() => {
    count--;
    sendCodeText.value = `${count}秒后重试`;
    if (count <= 0) {
      clearInterval(timer);
      sendCodeDisabled.value = false;
      sendCodeText.value = "发送验证码";
    }
  }, 1000);
};

const handleBindPhone = () => {
  console.log("绑定手机号:", bindPhoneForm.value);
  // 处理绑定手机号逻辑
  if (bindPhoneForm.value.phone === "" || bindPhoneForm.value.phone === null) {
    return Message.warning("请输入手机号");
  } else if (bindPhoneForm.value.code === "") {
    return Message.warning("请输入验证码");
  }
  const data = {
    userId: userId.value,
    userPhoneNumber: bindPhoneForm.value.phone,
    verificationCode: bindPhoneForm.value.code,
  };
  changeBindPhoneBtnLoading.value = true;
  bindUserPhone(data)
    .then((res) => {
      console.log(res);
      Message.success("绑定手机号成功");
    })
    .catch((err) => {
      console.log(err);
      Message.error(err.message);
    })
    .finally(() => {
      changeBindPhoneBtnLoading.value = false;
      showBindPhoneModal.value = false;
    });
};

// 监听一级弹窗的手机号变化
watch(
  () => userInfo.value.userPhoneNumber,
  (newVal) => {
    bindPhoneForm.value.phone = newVal;
  }
);

// 修改密码弹窗相关逻辑
const showChangePasswordModal = ref(false);
const changePasswordForm = ref({
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
});

const handleChangePassword = () => {
  console.log("修改密码:", changePasswordForm.value);
  // 处理修改密码逻辑
  if (changePasswordForm.value.oldPassword === "") {
    return Message.warning("请输入原始密码");
  } else if (changePasswordForm.value.newPassword === "") {
    return Message.warning("请输入新密码");
  } else if (changePasswordForm.value.confirmPassword === "") {
    return Message.warning("请再次确认新密码");
  } else if (
    changePasswordForm.value.newPassword !==
    changePasswordForm.value.confirmPassword
  ) {
    return;
  }
  changePasswordBtnLoading.value = true;
  const data = {
    userId: userId.value,
    oldPassword: changePasswordForm.value.oldPassword,
    newPassword: changePasswordForm.value.newPassword,
    newPasswordRepeat: changePasswordForm.value.confirmPassword,
  };
  updateUserPassword(data)
    .then((res: any) => {
      console.log(res);
      Message.success("修改密码成功");
    })
    .catch((err: any) => {
      console.log(err);
      Message.error(err.message);
    })
    .finally(() => {
      showChangePasswordModal.value = false;
      changePasswordBtnLoading.value = false;
    });
};

// 监听 confirmPassword 的失焦事件
const validateConfirmPassword = () => {
  if (
    changePasswordForm.value.newPassword !==
    changePasswordForm.value.confirmPassword
  ) {
    Message.warning("两次输入的新密码不一致");
  }
};
</script>

<template>
  <n-modal
    v-model:show="props.show"
    preset="card"
    :closable="false"
    :style="{ width: '1000px' }"
    :mask-closable="false"
    :on-after-leave="afterClose"
  >
    <template #header>
      <h2 style="text-align: center">个人信息</h2>
    </template>
    <template #default>
      <n-form ref="formRef" label-placement="left" label-width="80px">
        <div class="form-row">
          <n-form-item label="昵称">
            <n-input
              round
              v-model:value="userInfo.nickName"
              placeholder="请输入昵称"
            />
          </n-form-item>
          <n-form-item label="用户名">
            <n-input
              disabled
              round
              v-model:value="userInfo.userName"
              placeholder="请输入用户名"
            />
          </n-form-item>
          <n-form-item label="手机号">
            <div style="display: flex; align-items: center">
              <n-input
                round
                disabled
                v-model:value="userInfo.userPhoneNumber"
                placeholder="请输入手机号"
              />
              <n-button
                text
                style="margin-left: 8px"
                @click="showBindPhoneModal = true"
                >绑定</n-button
              >
            </div>
          </n-form-item>
        </div>
        <div class="form-row">
          <n-form-item label="性别">
            <n-select
              style="border-radius: 10px"
              v-model:value="userInfo.gender"
              :options="genderOptions"
              placeholder="请选择性别"
            />
          </n-form-item>
          <n-form-item label="密码">
            <div style="display: flex; align-items: center">
              <n-input
                round
                disabled
                type="password"
                v-model:value="userInfo.userPassword"
                placeholder="请输入密码"
              />
              <n-button
                text
                style="margin-left: 8px"
                @click="showChangePasswordModal = true"
                >修改</n-button
              >
            </div>
          </n-form-item>
          <n-form-item label="公司">
            <n-input
              round
              v-model:value="userInfo.company"
              placeholder="请输入公司"
            />
          </n-form-item>
        </div>
        <div class="form-row">
          <n-form-item label="职位">
            <n-input
              round
              v-model:value="userInfo.position"
              placeholder="请输入职位"
            />
          </n-form-item>
          <n-form-item label="行业">
            <n-input
              round
              v-model:value="userInfo.industry"
              placeholder="请输入行业"
            />
          </n-form-item>
          <n-form-item label="所在地">
            <n-input
              round
              v-model:value="userInfo.location"
              placeholder="请输入所在地"
            />
          </n-form-item>
        </div>
      </n-form>
    </template>
    <template #footer>
      <n-space justify="center">
        <n-button @click="emit('update:show', false)">取消</n-button>
        <n-button type="primary" @click="handleSave">保存</n-button>
      </n-space>
    </template>
  </n-modal>

  <!-- 绑定手机号弹窗 -->
  <n-modal
    v-model:show="showBindPhoneModal"
    preset="card"
    :closable="false"
    :style="{ width: '500px' }"
    :mask-closable="false"
  >
    <template #header>
      <h2 style="text-align: center">绑定手机号</h2>
    </template>
    <template #default>
      <n-form ref="bindPhoneFormRef" label-placement="left" label-width="80px">
        <n-form-item label="手机号">
          <n-input
            round
            v-model:value="bindPhoneForm.phone"
            placeholder="请输入手机号"
          />
        </n-form-item>
        <n-form-item label="验证码">
          <div style="display: flex; align-items: center">
            <n-input
              round
              v-model:value="bindPhoneForm.code"
              placeholder="请输入验证码"
            />
            <n-button
              text
              style="margin-left: 8px"
              :disabled="sendCodeDisabled"
              @click="sendCode"
              >{{ sendCodeText }}</n-button
            >
          </div>
        </n-form-item>
      </n-form>
    </template>
    <template #footer>
      <n-space justify="center">
        <n-button @click="showBindPhoneModal = false">取消</n-button>
        <n-button
          :loading="changeBindPhoneBtnLoading"
          type="primary"
          @click="handleBindPhone"
          >确定</n-button
        >
      </n-space>
    </template>
  </n-modal>

  <!-- 修改密码弹窗 -->
  <n-modal
    v-model:show="showChangePasswordModal"
    preset="card"
    :closable="false"
    :style="{ width: '500px' }"
    :mask-closable="false"
    title="修改密码"
  >
    <template #default>
      <n-form
        ref="changePasswordFormRef"
        label-placement="left"
        label-width="120px"
      >
        <n-form-item label="原始密码">
          <n-input
            round
            type="password"
            show-password-on="click"
            v-model:value="changePasswordForm.oldPassword"
            placeholder="请输入原始密码"
          />
        </n-form-item>
        <n-form-item label="新密码">
          <n-input
            round
            type="password"
            show-password-on="click"
            v-model:value="changePasswordForm.newPassword"
            placeholder="请输入新密码"
          />
        </n-form-item>
        <n-form-item label="再次确认新密码">
          <n-input
            round
            type="password"
            show-password-on="click"
            v-model:value="changePasswordForm.confirmPassword"
            placeholder="请再次确认新密码"
            @blur="validateConfirmPassword"
          />
        </n-form-item>
      </n-form>
    </template>
    <template #footer>
      <n-space justify="center">
        <n-button @click="showChangePasswordModal = false">取消</n-button>
        <n-button
          :loading="changePasswordBtnLoading"
          type="primary"
          @click="handleChangePassword"
          >确定</n-button
        >
      </n-space>
    </template>
  </n-modal>
</template>

<style scoped>
.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.form-row .n-form-item {
  flex: 1;
  margin-right: 16px;
}

.form-row .n-form-item:last-child {
  margin-right: 0;
}

/* 添加虚线样式 */
.form-row:nth-child(2) {
  border-bottom: 1px dashed #d9d9d9;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

:deep(.n-base-selection__border) {
  border-radius: 10px;
}
:deep(.n-base-selection__state-border) {
  border-radius: 10px;
}
</style>
