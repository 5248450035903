import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";

import {
  // getUserMeApi,
  // loginApi,
  LoginData,
  logoutApi,
  registerMuGua,
  RegisterData,
  loginMuguaApi,
  getMuguaUserMeApi,
  loginSmsMuguaApi,
} from "@/api/user";
import { UserRead } from "@/types/schema";
import { clearCookie } from "@/utils/auth";
import { Dialog, Message } from "@/utils/tips";
import { UserState } from "../types";

const useUserStore = defineStore("user", {
  state: (): UserState => ({
    user: null,
    javaUser: null,
    savedLoginForm: useStorage("savedLoginForm", {
      rememberPassword: false,
      savedUsername: undefined,
      savedPassword: undefined,
    }),
    settingVisibel: {
      longDocument: false,
      proFile: true,
      proUrl: false,
    },
    modalShown: 0,
    dialogue: true,
    case: true,
    analysis: true,
    qaFlag:
      localStorage.getItem("qaFlag") &&
      localStorage.getItem("qaFlag") === "true"
        ? true
        : false,
  }),
  getters: {
    userInfo(): UserRead | null {
      return this.user;
    },
  },

  actions: {
    setAnalysis(flag: boolean) {
      this.$patch({ analysis: flag });
    },
    setDialogue(flag: boolean) {
      this.$patch({ dialogue: flag });
    },
    setCase(flag: boolean) {
      this.$patch({ case: flag });
    },
    // Set user's information
    setInfo(user: UserRead) {
      this.$patch({ user });
    },

    setJavaUserInfo(javaUser: any) {
      this.javaUser = javaUser;
    },

    setQaFlag(flag: boolean) {
      this.$patch({ qaFlag: flag });
    },

    setSavedLoginInfo(username: string, password: string) {
      this.savedLoginForm.savedUsername = username;
      this.savedLoginForm.savedPassword = password;
    },

    // Reset user's information
    resetInfo() {
      this.$reset();
    },

    // Get user's information
    // async fetchUserInfo() {
    //   const result = (await getUserMeApi()).data;
    //   this.setInfo(result);
    // },

    // Get user's information
    async getMuguaUserInfo() {
      try {
        const result = (await getMuguaUserMeApi()).data.data;
        this.setJavaUserInfo(result);
      } catch (err: any) {
        console.log(err);
        if (err.code === 3006) {
          Dialog.info({
            title: "温馨提示",
            content: "如果登录失败,可以尝试清除缓存再试一下~",
            positiveText: "清除缓存",
            negativeText: "取消",
            onPositiveClick: async () => {
              await clearCookie();
            },
          });
        } else {
          Message.error(err.message);
        }
      }
    },

    // Login
    // async login(loginForm: LoginData) {
    //   try {
    //     await loginApi(loginForm);
    //     // setToken(res.data.token);
    //   } catch (err) {
    //     clearCookie();
    //     throw err;
    //   }
    // },

    // Login
    async loginMugua(loginForm: LoginData) {
      try {
        let res;
        if (loginForm.verificationCode && loginForm.verificationCode !== "") {
          console.log(loginForm);
          res = await loginSmsMuguaApi(loginForm);
        } else {
          res = await loginMuguaApi(loginForm);
        }
        localStorage.setItem("javaToken", res.data.data.token);
        localStorage.setItem("freshToken", res.data.data.freshToken);
        localStorage.setItem("qaFlag", res.data.data.qaFlag);
        this.setQaFlag(res.data.data.qaFlag);
        console.log(res.data.data.user);
        this.setJavaUserInfo(res.data.data.user);
      } catch (err) {
        clearCookie();
        throw err;
      }
    },

    // register
    async registerMuGua(loginForm: RegisterData) {
      try {
        await registerMuGua(loginForm);
        // setToken(res.data.token);
      } catch (err) {
        clearCookie();
        throw err;
      }
    },

    // Logout
    async logout() {
      try {
        await logoutApi();
      } finally {
        this.resetInfo();
        clearCookie();
      }
    },
    setModalShown() {
      this.modalShown = this.modalShown + 1;
    },
  },
});

export default useUserStore;
