<template>
  <n-layout-header
    bordered
    style="height: var(--header-height)"
    class="px-3 flex flex-col justify-center"
  >
    <n-page-header>
      <template #title>
        <n-space :align="'center'">
          <div style="margin-left: 20px" class="inline-block">
            <n-dropdown :options="getCloudOptions()" placement="bottom-start">
              <n-button
                style="font-size: 15px; font-weight: 700"
                :bordered="false"
              >
                <n-ellipsis :tooltip="false" style="max-width: 6rem">
                  法律咨询
                </n-ellipsis>
                <template #icon>
                  <n-icon><CloudOutline /></n-icon>
                </template>
              </n-button>
            </n-dropdown>
          </div>
          <n-menu
            @update:value="handleMenuSelect"
            v-model:value="activeKey"
            mode="horizontal"
            :collapsed-width="64"
            :collapsed-icon-size="22"
            :options="menuOptions"
          />
        </n-space>
      </template>
      <template #avatar>
        <n-icon
          @click="handleHomeClick"
          style="margin-left: 12px; cursor: pointer"
          size="28"
          ><HomeFilled></HomeFilled
        ></n-icon>
      </template>
      <template #extra>
        <n-space>
          <div class="flex space-x-2 items-center">
            <n-button
              style="margin-right: 10px"
              text
              @click="() => (showConversationModal = true)"
            >
              <n-icon style="margin-right: 5px"
                ><ChatbubbleEllipsesOutline></ChatbubbleEllipsesOutline
              ></n-icon>
              开通VIP</n-button
            >
            <n-button style="margin-right: 10px" text @click="showCardPreset">
              <n-icon style="margin-right: 5px"
                ><ShareSocialOutline></ShareSocialOutline
              ></n-icon>
              邀请码</n-button
            >
            <n-modal
              v-model:show="showModal"
              class="custom-card"
              preset="card"
              :style="{
                width: '600px',
              }"
              size="huge"
              :bordered="false"
              header-style="padding:15px"
            >
              <n-tabs type="line">
                <n-tab-pane name="invitationCode" tab="邀请码">
                  <div>
                    <div
                      style="
                        display: flex;
                        margin-top: 10px;
                        margin-bottom: 20px;
                      "
                    >
                      <n-input
                        style="margin-right: 10px"
                        :value="invitationCodeValue"
                        type="text"
                        placeholder="邀请好友加入木瓜法航AI, 开启体验之旅!"
                      />
                      <n-button @click="handelCopy">复制邀请码</n-button>
                    </div>
                    <p>邀请好友注册免费获得2次合同审查的机会</p>
                    <br />
                    <!-- <div>已成功邀请2人</div> -->
                  </div>
                </n-tab-pane>
                <n-tab-pane name="experienceCard" tab="体验卡">
                  <div>
                    <div
                      style="
                        display: flex;
                        margin-top: 10px;
                        margin-bottom: 20px;
                      "
                    >
                      <n-input
                        style="margin-right: 10px"
                        v-model:value="experienceValue"
                        type="text"
                        placeholder="请输入体验卡激活码"
                      />
                      <n-button type="info" @click="handelActivation"
                        >激活</n-button
                      >
                    </div>
                    <p>
                      温馨提示: 请查看体验卡中的激活码, 输入激活码进行激活,
                      如在激活过程中遇到任何问题, 可以通过我们的客服联系我们,
                      我们会尽快回复并协助解决问题
                    </p>
                  </div>
                </n-tab-pane>
              </n-tabs>
              <template #footer>
                <div style="width: 100%; text-align: center">
                  <n-button type="info" @click="() => (showModal = false)"
                    >关闭</n-button
                  >
                </div>
              </template>
            </n-modal>
            <CustomerServiceModal v-model:show="showConversationModal" />
            <OrderManagementModal
              v-model:modelValue="showOrderManagementModal"
            />
            <div v-if="userStore.javaUser" class="inline-block">
              <n-dropdown :options="getOptions()" placement="bottom-start">
                <n-button strong round secondary class="px-2">
                  <n-ellipsis :tooltip="false" style="max-width: 6rem">
                    {{ userStore.javaUser.nickName }}
                  </n-ellipsis>
                  <template #icon>
                    <n-icon><PersonCircleOutline /></n-icon>
                  </template>
                </n-button>
              </n-dropdown>
            </div>
            <div v-else class="text-gray-500 inline-block">
              {{ $t("commons.notLogin") }}
            </div>
            <n-button
              v-if="userStore.javaUser?.isSuperUser"
              secondary
              circle
              @click="jumpToAdminOrConv"
            >
              <n-icon
                :component="isInAdmin ? ChatFilled : ManageAccountsFilled"
              />
            </n-button>
            <!-- <n-button secondary circle @click="toggleTheme">
              <n-icon :component="themeIcon" />
            </n-button> -->
          </div>
        </n-space>
      </template>
    </n-page-header>
  </n-layout-header>
  <UserInfoModal
    v-model:show="showUserSpaceModal"
    @save="handleUserSave"
    @update:show="handleCancel"
  />
</template>

<script setup lang="ts">
import {
  InformationCircleOutline,
  LogOutOutline,
  PersonCircleOutline,
  ShareSocialOutline,
  ChatbubbleEllipsesOutline,
} from "@vicons/ionicons5";
import {
  ChatFilled,
  DarkModeRound,
  LightModeRound,
  ManageAccountsFilled,
  HomeFilled,
} from "@vicons/material";
import { DropdownOption, NIcon, NButton, useModal } from "naive-ui";
import { computed, h, ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import { updateUserInfo } from "@/api/user";
import router from "@/router";
import { useAppStore, useUserStore } from "@/store";
import {
  useCloudStore,
  useConversationStore,
  useDocumentStore,
  useFunctionalStore,
  useFileStore,
} from "@/store";
import { popupResetUserPasswordDialog } from "@/utils/renders";
import { Dialog, Message } from "@/utils/tips";
import { ChatbubbleEllipses, CloudOutline } from "@vicons/ionicons5";
import { AlignBoxTopLeft, UserProfile } from "@vicons/carbon";
import { getInvitationCode, useActivationCode } from "@/api/newuser";
import CustomerServiceModal from "./CustomerServiceModal.vue";
import OrderManagementModal from "./OrderManagementModal.vue";

const { t } = useI18n();
const userStore = useUserStore();
const appStore = useAppStore();
const route = useRoute();
// const version = 'v' + import.meta.env.PACKAGE_VERSION;
const activeKey = ref<string>(router.currentRoute.value.name as string);
const cloudStore = useCloudStore();
const conversationStore = useConversationStore();
const documentStore = useDocumentStore();
const functionalStore = useFunctionalStore();
const fileStore = useFileStore();
const showModal = ref(false);
const showConversationModal = ref(false);
const invitationCodeValue = ref("");
const experienceValue = ref("");
const showUserSpaceModal = ref(false);
const showOrderManagementModal = ref(false);

function openModal() {
  showUserSpaceModal.value = true;
}

function handleCancel() {
  showUserSpaceModal.value = false;
}

const showOrderManagement = () => {
  showOrderManagementModal.value = true;
};

function handleUserSave(info: any) {
  console.log("父组件接收到的用户信息:", info);
  // 执行保存逻辑
  const data = {
    userId: userStore.javaUser.userId,
    userName: info.userName,
    nickName: info.nickName,
    gender: info.gender,
    company: info.company,
    position: info.position,
    industry: info.industry,
    location: info.location,
  };
  updateUserInfo(data)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
  showUserSpaceModal.value = false;
}

// console.log(route);
function renderIcon(icon: any) {
  return () => h(NIcon, null, { default: () => h(icon) });
}

// 创建一个计算属性来动态地设定菜单
const menuOptions = computed(() => {
  const baseOptions = [];

  if (userStore.qaFlag) {
    // baseOptions.push({
    //   label: t("commons.intelligentConversation"),
    //   key: "conversation",
    //   icon: renderIcon(ChatbubbleEllipses),
    // });
  }

  if (userStore.settingVisibel.longDocument) {
    // 只有 showLongDocument 为 true 时才添加这个菜单项
    // baseOptions.push({
    //   label: t("commons.longdocument"),
    //   key: "longdocumentManagement",
    //   icon: renderIcon(AlignBoxTopLeft),
    // });
  }

  baseOptions.push(
    {
      label: "合同审查",
      key: "contractReview",
      icon: renderIcon(AlignBoxTopLeft),
    }
    // {
    //   label: "案情分析",
    //   key: "analysis",
    //   icon: renderIcon(ChatbubbleEllipses),
    // },
    // {
    //   label: "起草文书",
    //   key: "drafting",
    //   icon: renderIcon(CloudOutline),
    // }
  );
  return baseOptions;
});

onMounted(() => {
  if (invitationCodeValue.value === "") {
    getInvitationCode()
      .then((res) => {
        console.log(res);
        invitationCodeValue.value = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
});

const handelCopy = async () => {
  if (invitationCodeValue.value !== "") {
    try {
      const textToCopy = invitationCodeValue.value;
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(textToCopy);
        Message.success("复制成功");
      } else {
        Message.error("该浏览器不支持一键复制功能,请您手动复制邀请码");
      }
    } catch (err) {
      Message.error("复制失败");
    }
  }
};

const handelActivation = () => {
  if (experienceValue.value.trim() !== "") {
    useActivationCode(userStore.javaUser.userId, experienceValue.value.trim())
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          Message.success(res.data.message);
        } else {
          Message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        Message.error(err.message);
      });
  } else {
    Message.info("请输入您的体验卡");
  }
};

const showCardPreset = () => {
  showModal.value = true;
};

const isInAdmin = computed(() => {
  return route.path.startsWith("/admin");
});

const themeIcon = computed(() => {
  if (appStore.theme == "dark") {
    return DarkModeRound;
  } else {
    return LightModeRound;
  }
});

const toggleTheme = () => {
  appStore.toggleTheme();
};

const handleHomeClick = () => {
  router.push("/homepage");
};

const getOptions = (): Array<DropdownOption> => {
  const options: Array<DropdownOption> = [
    {
      label: "个人空间",
      key: "userSpace",
      icon() {
        return h(NIcon, null, {
          default: () => h(InformationCircleOutline),
        });
      },
      props: {
        onClick: () => {
          openModal();
        },
      },
    },
    // {
    //   label: "订单管理",
    //   key: "order",
    //   icon() {
    //     return h(NIcon, null, {
    //       default: () => h(LogOutOutline),
    //     });
    //   },
    //   props: {
    //     onClick: () => {
    //       showOrderManagement();
    //     },
    //   },
    // },
    {
      label: t("commons.logout"),
      key: "logout",
      icon() {
        return h(NIcon, null, {
          default: () => h(LogOutOutline),
        });
      },
      props: {
        onClick: () =>
          Dialog.info({
            title: t("commons.logout"),
            content: t("tips.logoutConfirm"),
            positiveText: t("commons.confirm"),
            negativeText: t("commons.cancel"),
            onPositiveClick: async () => {
              await userStore.logout();
              cloudStore.resetCloud();
              conversationStore.resetConversation();
              documentStore.resetDocument();
              functionalStore.resetFunctional();
              fileStore.clear();
              Message.success(t("commons.logoutSuccess"));
              await router.push({ name: "login" });
            },
          }),
      },
    },
  ];
  return options;
};

const getCloudOptions = (): Array<DropdownOption> => {
  const options: Array<DropdownOption> = [
    {
      label: "通道一",
      key: "channelOne",
      icon() {
        return h(NIcon, null, {
          default: () => h(InformationCircleOutline),
        });
      },
      props: {
        onClick: () => {
          router.push({ name: "conversationpro" });
        },
      },
    },
    {
      label: "通道二",
      key: "channelTwo",
      icon() {
        return h(NIcon, null, {
          default: () => h(InformationCircleOutline),
        });
      },
      props: {
        onClick: () => router.push({ name: "dialogue" }),
      },
    },
  ];
  return options;
};

const jumpToAdminOrConv = async () => {
  if (isInAdmin.value) {
    await router.push({ name: "homePage" });
  } else {
    await router.push({ name: "newUserManagement" });
  }
};

function handleMenuSelect(key: string) {
  console.log(`Navigating to: ${key}`);
  router.push({ name: key });
}
</script>
<style scoped>
.card {
  width: 300px;
  padding: 20px;
  border: 1px solid #eee;
}

.tag {
  position: absolute;
  top: -12px;
  left: -12px;
  padding: 0 8px;
}

.title {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.price {
  margin-top: 10px;
  font-size: 24px;
  color: #1d75ee;
}

.current-price {
  font-weight: bold;
}

.unit {
  font-size: 14px;
  margin-right: 5px;
  color: black;
}

.original-price {
  font-size: 14px;
  color: #999;
  text-decoration: line-through;
}

.features {
  margin-top: 10px;
  padding-left: 20px;
}

.features li {
  margin-bottom: 5px;
}
</style>
