import axios from "axios";

import ApiUrl from "./url";

export function getRightsListApi(isDelete: boolean) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.PayGetRightsList,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      isDelete,
    },
  };
  return axios.request(config);
}

export function checkPaymentStatusApi(orderId: string) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.PayCheckOrderls,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      orderId,
    },
  };
  return axios.request(config);
}
export function getNewOrderApi(userId: string) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.PaygetNewOrder,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userId,
    },
  };
  return axios.request(config);
}
